<template>
  <ion-modal
    class="modal-small"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="w-100">
          <div class="d-flex justify-content-end mt-3 align-items-center">
            <ion-input
              :minlength="0"
              :maxlength="50"
              :placeholder="user.email"
              v-model="emailExample"
              class="c-input"
            />
            <ChLoading size="sm" v-if="checking" class="spinner mr-2" />
          </div>
          <div class="hint mt-2">
            We will send a verification email to this address. Email will be updated after it's successfully verified.
          </div>
        </div>
        <div class="actions d-flex mt-3">
          <ion-button :disabled="!emailExample || isSaving || checking" @click="save"> Save </ion-button>

          <ion-button color="medium" @click="dismissModal"> Cancel </ion-button>
        </div>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts" setup>
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';

const { user, updateUserEmail } = authStore();

const isSaving = ref(false);
const checking = ref(false);

defineProps({
  modalTitle: {
    type: String,
    default: 'Change email',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['dismissModal']);

const dismissModal = () => {
  emits('dismissModal');
};

onMounted(() => {
  emailExample.value = user.value.email;
});

const emailExample = ref('');

const save = async () => {
  try {
    isSaving.value = true;
    await updateUserEmail(emailExample.value);
    dismissModal();
  } catch {
    toast.show('Error changing email', 'nontive', 'danger');
  } finally {
    isSaving.value = false;
  }
};
</script>

<style lang="sass" scoped>
ion-modal
  --height: fit-content !important
.spinner
  opacity: 0.5
  position: absolute
  z-index: 22
  width: 16px
  height: 16px
.hint
  color: #999
  font-size: 12px

.modal-small
  --height: 250px
.error-msg
  color: var(--ion-color-danger)
  font-size: 14px
  width: 100%
  text-align: start
</style>
